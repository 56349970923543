import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import AliceCarousel from "react-alice-carousel"
import { Avatar, Box, Card, CardHeader, CardMedia } from "@material-ui/core"
const AnasayfaCarousel = () => {
  const { anasayfaslider, resimler } = useStaticQuery(graphql`
    query {
      resimler: allFile(
        filter: {
          relativeDirectory: { eq: "filtre-carousel" }
          ext: { in: [".jpg", ".png"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      anasayfaslider: file(relativePath: { eq: "sliderlar.json" }) {
        childDataJson {
          anasayfa {
            imgAlt
            imgName
            url
          }
        }
      }
    }
  `)
  const sliderlar = anasayfaslider.childDataJson.anasayfa
  const responsive = {
    0: { items: 1 },
    760: { items: 2 },
    1024: { items: 3 },
  }
  return (
    <AliceCarousel
      mouseTrackingEnabled
      responsive={responsive}
      autoPlayInterval={2000}
      autoPlay={true}
      fadeOutAnimation={true}
      buttonsDisabled={true}
    >
      {resimler.edges.map(({ node }, index) => (
        <Box p={2} key={sliderlar[index].imgName}>
          <Link to={sliderlar[index].url} style={{ textDecoration: "none" }}>
            <Card elevation={4}>
              <CardHeader
                avatar={
                  <Avatar
                    style={{ backgroundColor: "#ef5350" }}
                    aria-label="Erez KAYNAK"
                  >
                    E
                  </Avatar>
                }
                title="EreZ KAYNAK"
                subheader={sliderlar[index].imgAlt}
              />
              <CardMedia>
                <Img
                  className=""
                  fluid={node.childImageSharp.fluid}
                  alt={sliderlar[index].imgAlt}
                />
              </CardMedia>
            </Card>
          </Link>
        </Box>
      ))}
    </AliceCarousel>
  )
}
export default AnasayfaCarousel
